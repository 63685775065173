import React, { useState } from "react";
import styled from "styled-components";

const Element = styled.div`
  position: relative;
  clear: both;
  padding-top: 47px;
`;

const Player = ({ children, isMobile, width, height }) => {
  const [started, setStarted] = useState(false);

  const handlePlayVideo = () => {
    let media = document.querySelector("video");
    if (media.paused) {
      setStarted(true);
      media.play();
    } else {
      media.pause();
    }
  };

  let w = 829;
  let h = 499;

  if (width < 1300) {
    w = 650;
    h = 391;
  }

  return (
    <Element
      style={{
        // paddingTop: isMobile ? 47 : 0,
        width: isMobile ? width : w,
        height: isMobile ? (width * 499) / 829 : h,
        float: isMobile ? "none" : "right",
      }}
    >
      <video
        id="player"
        style={{
          width: isMobile ? width : w,
          height: isMobile ? (width * 499) / 829 : h,
          marginLeft: isMobile ? -32 : 0,
          cursor: "pointer",
          position: "relative",
        }}
        poster="/cover.png"
        controls={started}
        loop
        onClick={handlePlayVideo}
      >
        <source src="/video.mp4" type="video/mp4" />
      </video>
    </Element>
  );
};

export default Player;
