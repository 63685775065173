import React from "react";
import styled from "styled-components";

const Element = styled.div``;

const Title = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 80%;
  padding-bottom: 12px;
`;

const SubTitle = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  padding-bottom: 24px;
`;

const Header = ({ children, isMobile }) => {
  return (
    <Element style={{}}>
      <Title>D3</Title>
      <SubTitle>02 ⸺ 22</SubTitle>
    </Element>
  );
};

export default Header;
