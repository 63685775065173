import React from "react";
import styled from "styled-components";

const Element = styled.div``;

const Title = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-top: 56px;
  max-width: 754px;
`;

const SubTitle = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  max-width: 754px;
`;

const Hash = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  max-width: 754px;
`;

const Intro = ({ children, isMobile }) => {
  return (
    <Element>
      <Title>
        Após 20 anos construindo o mundo que queremos viver, nossa jornada neste
        formato se encerra.
        <br />
        <br />
      </Title>
      <SubTitle>
        Mas até o fim, pode se revelar lindo. <br />
        Lindo por nossa história, nossa jornada, pelo que lançamos ao mundo,
        pela transformação que causamos, pelas amizades que fizemos, pela
        quantidade de sementes que plantamos nesse mundo. <br />
        Temos muito a celebrar, mesmo sabendo que o mundo não está pronto
        acreditamos que, se tudo der certo, ele nunca estará.
        <br />
        <br />
      </SubTitle>
      <Hash>#D3SeasonFinale.</Hash>
    </Element>
  );
};

export default Intro;
