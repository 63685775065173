import React from "react";
import styled from "styled-components";

const Element = styled.div``;

const Title = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  max-width: 366px;
`;
const SubTitle = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  max-width: 261px;
`;

const Featured = ({ children, isMobile }) => {
  return (
    <Element>
      <Title
        style={{
          paddingTop: isMobile ? 56 : 56,
          clear: isMobile ? "both" : "inherit",
          maxWidth: isMobile ? 5000 : 366,
        }}
      >
        Um memorial dedicado às pessoas íncríveis que fizeram parte dessa
        história.
      </Title>
      <SubTitle
        style={{
          paddingTop: isMobile ? 40 : 40,
          maxWidth: isMobile ? 5000 : 261,
        }}
      >
        Aqui encontramos as pessoas que fizeram parte de 2022 representando
        todes que construíram com a gente esses 20 anos. <br />
        <br />
        Somos felizes em celebrar tudo que vivemos juntos e a certeza que irão
        continuar construindo o mundo que queremos viver.
      </SubTitle>
    </Element>
  );
};

export default Featured;
