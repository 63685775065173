import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Element = styled.div`
  float: right;
  width: 310px;
`;

const Link = styled.a`
  width: 135px;
  display: block;
  float: left;
  margin-top: -2px;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
`;
const LinkLogo = styled.div`
  float: right;
  margin-top: -4px;
`;

const Links = ({ children, isMobile }) => {
  return (
    <Element
      style={{
        float: isMobile ? "none" : "right",
        paddingTop: isMobile ? 46 : 0,
        paddingBottom: isMobile ? 25 : 0,
      }}
    >
      <NavLink to="/memorial">
        <Link>
          <LinkLogo>
            <img src="/arrow.svg" alt="Linkedin" />
          </LinkLogo>
          Memorial D3
        </Link>
      </NavLink>
      <Link
        href="https://history.d3.do"
        target={"_blank"}
        style={{ marginLeft: 24, width: 150 }}
      >
        <LinkLogo>
          <img src="/arrow.svg" alt="Linkedin" />
        </LinkLogo>
        O que fizemos
      </Link>
    </Element>
  );
};

export default Links;
