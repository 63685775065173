import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Element = styled.div`
  margin-bottom: 48px;
  margin-left: -8px;
`;

const Base = ({ children, isMobile }) => {
  return (
    <Element>
      <NavLink to="/">
        <img src="/back.svg" alt="Voltar" />
      </NavLink>
    </Element>
  );
};

export default Base;
