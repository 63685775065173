import React from "react";
import styled from "styled-components";

const Element = styled.div`
  padding-top: 112px;
  padding-bottom: 86px;
`;
const Text = styled.div`
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: -3px;
  float: left;
`;
const Linkedin = styled.a`
  display: block;
  float: left;
  margin-left: 32px;
  margin-top: -2px;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
`;
const LinkedinLogo = styled.div`
  float: left;
  margin-right: 8px;
  margin-top: -4px;
`;

const Separator = styled.div`
  clear: both;
  height: 28px;
`;

const Footer = ({ children, isMobile, width, height }) => {
  let size = width < 1512 ? width : 1512;

  if (isMobile) {
    size = size - 64;
  } else {
    size = size - 112;
  }

  return (
    <Element
      style={{
        position: height < (isMobile ? 1305 : 993) ? "relative" : "absolute",
        bottom: height < (isMobile ? 1305 : 993) ? "auto" : 0,
        width: size,
      }}
    >
      <Text>Para saber o que os seus sócios estão fazendo:</Text>
      {isMobile && <Separator />}
      <Linkedin
        href="https://www.linkedin.com/in/joao-marcos-souza"
        target={"_blank"}
        style={{
          marginLeft: isMobile ? 0 : 32,
        }}
      >
        <LinkedinLogo>
          <img src="/linkedin.svg" alt="Linkedin" />
        </LinkedinLogo>
        João Marcos
      </Linkedin>
      <Linkedin
        href="https://www.linkedin.com/in/edsonpavoni"
        target={"_blank"}
      >
        <LinkedinLogo>
          <img src="/linkedin.svg" alt="Linkedin" />
        </LinkedinLogo>
        Edson Pavoni
      </Linkedin>
      {!isMobile && children}
    </Element>
  );
};

export default Footer;
