import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";

import Holder from "../components/holder";
import Intro from "../components/intro";
import Links from "../components/links";

export default function Home() {
  // const helper = Helper();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerWidth);

  let isMobile = false;
  if (width < 1120) {
    isMobile = true;
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();
  }, []);

  return (
    <Holder isMobile={isMobile}>
      <Header isMobile={isMobile} />
      <img src="/logo.svg" alt="D3: Season finale" />
      {width < 1120 && <Links isMobile={true} />}
      <Intro isMobile={isMobile} />
      <Footer isMobile={isMobile} width={width} height={height}>
        <Links />
      </Footer>
    </Holder>
  );
}
