import React, { useEffect, useState } from "react";
import Header from "../components/header";

import Holder from "../components/holder";
import Back from "../components/back";
import Player from "../components/player";
import Featured from "../components/featured";
import People from "../components/people";

export default function Memorial() {
  const [width, setWidth] = useState(window.innerWidth);

  let isMobile = false;
  if (width < 1120) {
    isMobile = true;
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();
  }, []);

  return (
    <Holder isMobile={isMobile}>
      <Back />
      <Header isMobile={isMobile} />
      <img src="/logo.svg" alt="D3: Season finale" />
      <Player isMobile={isMobile} width={width} height={(width / 16) * 9} />
      <Featured isMobile={isMobile} width={width} />
      <People isMobile={isMobile} width={width} />
    </Holder>
  );
}
