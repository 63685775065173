import { Routes, Route } from "react-router-dom";
import React from "react";

import "./style.css";

import Home from "./pages/home.js";
import Memorial from "./pages/memorial";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/memorial" element={<Memorial />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
