import React from "react";
import styled from "styled-components";

const Element = styled.div`
  max-width: 1512px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Holder = ({ children, isMobile }) => {
  return (
    <Element
      style={{
        paddingTop: isMobile ? 24 : 24,
        paddingLeft: isMobile ? 32 : 56,
        paddingRight: isMobile ? 32 : 56,
      }}
    >
      {children}
    </Element>
  );
};

export default Holder;
